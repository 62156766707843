import React from "react";
import Separator from "../../img/page-separator.png";
import Navigation from "../../components/navigation";
import {
  fadeIn,
  fadeInDown,
  fadeInUp,
  fadeInRight,
  fadeInLeft,
  slideInRight,
  slideOutLeft,
  fadeOut,
} from "react-animations";
import Radium, { StyleRoot } from "radium";
import { Helmet } from "react-helmet";
import "./style.scss";

const styles = {
  fadeInDown: {
    animation: "x 2s",
    animationName: Radium.keyframes(fadeInDown, "fadeInDown"),
  },
  fadeInUp: {
    animation: "2s",
    animationName: Radium.keyframes(fadeInUp, "fadeInUp"),
  },
  fadeInRight: {
    animation: "1.5s",
    animationName: Radium.keyframes(fadeInRight, "fadeInRight"),
  },
  fadeIn: {
    animation: "3s",
    animationName: Radium.keyframes(fadeIn, "fadeIn"),
  },
  fadeInLeft: {
    animation: "1.5s",
    animationName: Radium.keyframes(fadeInLeft, "fadeInLeft"),
  },
  slideInRight: {
    animation: "1.5s",
    animationName: Radium.keyframes(slideInRight, "slideInRight"),
  },
  slideOutLeft: {
    animation: "1.5s",
    animationName: Radium.keyframes(slideOutLeft, "slideOutLeft"),
  },
  fadeOut: {
    animation: "0.4s",
    animationName: Radium.keyframes(fadeOut, "fadeOut"),
  },
};

const Index = () => {
  return (
    <>
      <Helmet>
        <title>Madcon - Booking</title>
        <meta name="robots" content="index, follow" />
        <meta
          name="description"
          content="Welcome to the official website for Madcon."
        />
        <meta name="author" content="Madcon, Icon Recordings AS" />
        <meta
          name="keywords"
          content="madcon, madconlive, icon recordings, booking"
        />

        <meta name="twitter:card" content="summary" />
        <meta name="twitter:url" content="http://www.madconlive.com/" />
        <meta name="twitter:title" content="Madcon - Booking" />
        <meta
          name="twitter:description"
          content="Welcome to the official website for Madcon."
        />
        <meta
          name="twitter:image"
          content="http://www.madconlive.com/showcase.jpg"
        />

        <meta property="og:url" content="http://www.madconlive.com/" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Madcon - Booking" />
        <meta
          property="og:description"
          content="Welcome to the official website for Madcon."
        />
        <meta
          property="og:image"
          content="http://www.madconlive.com/showcase.jpg"
        />
      </Helmet>
      <section className="booking-section">
        <div className="inner-booking-container">
          <Navigation />
          <div className="booking-container">
            <StyleRoot>
              <h2 style={[styles.fadeInUp]} className="booking-title">
                Booking
              </h2>
              <img
                style={[styles.fadeInLeft]}
                alt="separator"
                className="separator"
                src={Separator}
              />
              <p style={[styles.fadeInDown]} className="booking-description">
                For booking inquiries or any other questions, please contact us
                using the button below.
              </p>
              <a href="mailto:thomas@timeoutagency.no">
                <button style={[styles.fadeIn]} className="booking-button">
                  <i className="far fa-envelope"></i> Contact our booking agent
                </button>
              </a>
            </StyleRoot>
          </div>
        </div>
      </section>
    </>
  );
};

export default Index;
