import React from "react";
import { Navbar, Nav, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import instagramIcon from "../../img/instagram_icon.png";
import facebookIcon from "../../img/facebook_icon.png";
import twitterIcon from "../../img/twitter_icon.png";
import logo from "../../img/logo.png";
import "./style.scss";

const Index = () => {
  const mobileView = useMediaQuery({ query: "(max-width: 767px)" });

  return mobileView ? (
    <header
      className="full-navigation mobile"
      data-aos="fade-down"
      data-aos-offset="200"
      data-aos-delay="250"
      data-aos-duration="1000"
    >
      <div className="inner-navigation-container">
        <Navbar className="mobile-navigation" collapseOnSelect expand="md">
          <Navbar.Brand href="/">
            <img alt="Madcon logo" className="navigation-logo" src={logo} />
          </Navbar.Brand>
          <Navbar.Toggle
            className="menu-toggle"
            aria-controls="responsive-navbar-nav"
          >
            <i className="menu-icon fal fa-bars"></i>
          </Navbar.Toggle>
          <Navbar.Collapse className="collapse" id="responsive-navbar-nav">
            <Nav className="ml-auto links-container">
              <Nav.Link className="header-link" href="/">
                Frontpage
              </Nav.Link>
              <Nav.Link className="header-link" href="/booking">
                Booking
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    </header>
  ) : (
    <header
      className="full-navigation desktop"
      data-aos="fade-down"
      data-aos-offset="200"
      data-aos-delay="250"
      data-aos-duration="1000"
    >
      <div className="inner-navigation-container">
        <Row className="full-navigation-row">
          <Col className="navigation-social-col my-auto">
            <div className="social-media-container">
              <a
                className="social-link"
                href="https://www.instagram.com/madconofficial/"
              >
                <img
                  alt="Instagram"
                  className="social-icon instagram"
                  src={instagramIcon}
                />
              </a>
              <a
                className="social-link"
                href="https://www.facebook.com/OfficialMadcon/"
              >
                <img
                  alt="Facebook"
                  className="social-icon facebook"
                  src={facebookIcon}
                />
              </a>
              <a
                className="social-link"
                href="https://twitter.com/madconofficial"
              >
                <img
                  alt="Twitter"
                  className="social-icon twitter"
                  src={twitterIcon}
                />
              </a>
            </div>
          </Col>
          <Col className="navigation-logo-col my-auto text-center">
            <img alt="Madcon logo" className="navigation-logo" src={logo} />
          </Col>
          <Col className="navigation-links-col my-auto">
            <div className="links-container">
              <Link to="/" className="nav-link text-light">
                Frontpage
              </Link>
              <Link to="/booking" className="nav-link text-light">
                Booking
              </Link>
            </div>
          </Col>
        </Row>
      </div>
    </header>
  );
};

export default Index;
