import React, { useState } from "react";
import ReactFullpage from "@fullpage/react-fullpage";
import Navigation from "../../components/navigation";
import Slide from "../../components/slide";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import "./style.scss";

const Frontpage = () => {
  const [index, setIndex] = useState(0);

  const allSongs = [
    {
      name: "Drowning",
      length: "02:32",
      release: "2023",
      label: "Icon Recordings AS",
      video: "https://www.youtube.com/embed/ojyqEMJhsGc",
      spotify:
        "https://open.spotify.com/track/7wkkTMBpoYNzC9f1DfWqRU?si=e97a3f61376b4ff0",
      itunes:
        "https://music.apple.com/no/album/drowning/1667863236?i=1667863237",
      streams: 1555284,
    },
    {
      name: "Beggin'- Frank Walker Remix",
      length: "03:14",
      release: "2021",
      label: "Icon Recordings AS",
      video: "https://www.youtube.com/embed/Q3z35q-ICi4",
      spotify:
        "https://open.spotify.com/track/55mjmUq3NuL6rt9aCcl4ZG?si=acfdff37116446d4",
      itunes:
        "https://music.apple.com/us/album/beggin-frank-walker-remix-single/1571877416",
      streams: 1555284,
    },
    {
      name: "Don't Worry feat. Ray Dalton",
      length: "03:34",
      release: "2015",
      label: "Icon Recordings AS",
      video: "https://www.youtube.com/embed/y74UPiaK7u0",
      spotify:
        "https://open.spotify.com/track/2Wf0uMfCpwLO1B2Up3NbXH?si=1aa014f3be1846e9",
      itunes:
        "https://music.apple.com/us/album/dont-worry-feat-ray-dalton/1024233960?i=1024234117",
      streams: 252364785,
    },
    {
      name: "Beggin",
      length: "03:38",
      release: "2008",
      label: "Icon Recordings AS",
      video: "https://www.youtube.com/embed/zrFI2gJSuwA",
      spotify:
        "https://open.spotify.com/track/7eXrGBrl1mBKhxlWX0IoOQ?si=03ae3afd874d47e2",
      itunes:
        "https://music.apple.com/gb/album/beggin-original-version/313416809?i=313417012",
      streams: 233062091,
    },
    {
      name: "Glow",
      length: "03:49",
      release: "2010",
      label: "Icon Recordings AS",
      video: "https://www.youtube.com/embed/P9QBK1W9Ag4",
      spotify:
        "https://open.spotify.com/track/4icNLSquKZp6e0iEkBn9RP?si=0f03cce1da3343cb",
      itunes: "https://music.apple.com/dk/album/glow/374534210?i=374534219",
      streams: 397110711,
    },
    {
      name: "One Life feat. Kelly Rowland",
      length: "03:30",
      release: "2013",
      label: "Icon Recordings AS",
      video: "https://www.youtube.com/embed/m0OfienFvUM",
      spotify:
        "https://open.spotify.com/track/39XfXqvM2X8ocGSP4cVY9t?si=495222f33794456e",
      itunes:
        "https://music.apple.com/no/album/one-life-feat-kelly-rowland/642783044?i=642783239",
      streams: 19689848,
    },
    {
      name: "Freaky Like Me feat. Ameerah",
      length: "03:09",
      release: "2010",
      label: "Icon Recordings AS",
      video: "https://www.youtube.com/embed/ntgW_zhJXWA",
      spotify:
        "https://open.spotify.com/track/1cLNKWPe00bdWndwjp8DwO?si=073c85593b634d72",
      itunes:
        "https://music.apple.com/us/album/freaky-like-me-feat-ameerah/398213288?i=398213300",
      streams: 18678795,
    },
    {
      name: "Keep My Cool",
      length: "03:07",
      release: "2015",
      label: "Icon Recordings AS",
      video: "https://www.youtube.com/embed/Q4VWn6LJK5c",
      spotify:
        "https://open.spotify.com/track/038JtByS90Gy52KtTuDycg?si=5c874443368044d5",
      itunes:
        "https://music.apple.com/au/album/keep-my-cool/1045957855?i=1045957858",
      streams: 12807434,
    },
    {
      name: "No Lies feat. Jesper Jenset",
      length: "02:58",
      release: "2019",
      label: "Icon Recordings AS",
      video: "https://www.youtube.com/embed/UXaqdgerCxo",
      spotify:
        "https://open.spotify.com/track/1ak6tV6IIHSETTOp1MY4Aw?si=910e2feef6c34990",
      itunes:
        "https://music.apple.com/us/album/no-lies-feat-jesper-jenset/1476305577?i=1476305586",
      streams: 4777587,
    },
    {
      name: "Shine On feat. R.I.O",
      length: "03:31",
      release: "2019",
      label: "Icon Recordings AS",
      video: "https://www.youtube.com/embed/Fhsah99U6r4",
      spotify:
        "https://open.spotify.com/track/1JteB7US7arTTwTLisTAO5?si=82df0d64407c4440",
      itunes:
        "https://music.apple.com/us/album/shine-on/1462064725?i=1462065042",
      streams: 6394668,
    },
    {
      name: "Callin You",
      length: "03:10",
      release: "2019",
      label: "Icon Recordings AS",
      video: "https://www.youtube.com/embed/Q2xKwnUyk-w",
      spotify:
        "https://open.spotify.com/track/4GQxj1UVKv9jo0sPQoEQpg?si=2c6f73d41e8d4592",
      itunes:
        "https://music.apple.com/tr/album/callin-you/1456386212?i=1456386213",
      streams: 2596371,
    },
    {
      name: "Contakt",
      length: "03:15",
      release: "2018",
      label: "Icon Recordings AS",
      video: "https://www.youtube.com/embed/z5g0n_LP3j4",
      spotify:
        "https://open.spotify.com/track/0hGtR66QktndVSLtzBjVkh?si=e16a8f91c47c4696",
      itunes:
        "https://music.apple.com/no/album/contakt/1341802805?i=1341804712",
      streams: 727727,
    },
    {
      name: "Drimmedua feat. Kamelen",
      length: "04:22",
      release: "2018",
      label: "Icon Recordings AS",
      video: "https://www.youtube.com/embed/d4ReQaFShH8",
      spotify:
        "https://open.spotify.com/track/5Lyxz7T8Lm489hS6sL3pn2?si=9083ab0c94cd4e10",
      itunes:
        "https://music.apple.com/no/album/drimmedua-feat-kamelen/1341802805?i=1341804710",
      streams: 2647409,
    },
    {
      name: "Ting & Tang feat. Arif",
      length: "04:01",
      release: "2017",
      label: "Icon Recordings AS",
      video: "https://www.youtube.com/embed/V0h5Q6QHmXY",
      spotify:
        "https://open.spotify.com/track/43fLuTrEakzRYNZRG4AipG?si=e92164eba2774d85",
      itunes:
        "https://music.apple.com/no/album/ting-tang-feat-arif/1341802805?i=1341804663",
      streams: 913334,
    },
    {
      name: "Got A Little Drunk",
      length: "03:28",
      release: "2017",
      label: "Icon Recordings AS",
      video: "https://www.youtube.com/embed/ii0sayr-Usg",
      spotify:
        "https://open.spotify.com/track/6ueDio9QAylSqLf1IF5zU6?si=33d3d94e15db40d3",
      itunes:
        "https://music.apple.com/us/album/got-a-little-drunk/1232470888?i=1232470896",
      streams: 15153910,
    },
    {
      name: "In My Head",
      length: "04:05",
      release: "2013",
      label: "Icon Recordings AS",
      video: "https://www.youtube.com/embed/kaRljQahEZg",
      spotify:
        "https://open.spotify.com/track/6r2qOqEasnQakyXU53ogNZ?si=f9cf0ce4dfc24764",
      itunes:
        "https://music.apple.com/no/album/in-my-head/597137463?i=597137466",
      streams: 6715603,
    },
    {
      name: "Kjører På feat. Timbuktu",
      length: "03:36",
      release: "2012",
      label: "Icon Recordings AS",
      video: "https://www.youtube.com/embed/v1IY1dEPRGs",
      spotify:
        "https://open.spotify.com/track/0lklqjZbaVTszVd73Vb3V1?si=108e863ee5dd4a7f",
      itunes:
        "https://music.apple.com/no/album/kj%C3%B8rer-p%C3%A5-feat-timbuktu/527181382?i=527181619",
      streams: 6732152,
    },
    {
      name: "Liar",
      length: "03:08",
      release: "2009",
      label: "Icon Recordings AS",
      video: "https://www.youtube.com/embed/8ZwHAjnKfmU",
      spotify:
        "https://open.spotify.com/track/2vGFAPt8dkymw0RXBSEFXG?si=92170dc9f3cc4074",
      itunes:
        "https://music.apple.com/nz/album/liar-radio-edit/306001909?i=306001920",
      streams: 3831616,
    },
    {
      name: "Back On The Road feat. Paperboys",
      length: "03:08",
      release: "2008",
      label: "Icon Recordings AS",
      video: "https://www.youtube.com/embed/7P-dnQWZvhE",
      spotify:
        "https://open.spotify.com/track/4fdyhFNeZPAJCyv3tsEPvC?si=40f004e4e5ce48d6",
      itunes:
        "https://music.apple.com/au/album/back-on-the-road-feat-paperboys/327676993?i=327677236",
      streams: 2186120,
    },
  ];

  return (
    <>
      <Helmet>
        <title>Madcon - Frontpage</title>
        <meta name="robots" content="index, follow" />
        <meta
          name="description"
          content="Welcome to the official website for Madcon."
        />
        <meta name="author" content="Madcon, Icon Recordings AS" />
        <meta
          name="keywords"
          content="madcon, madconlive, icon recordings, booking"
        />

        <meta name="twitter:card" content="summary" />
        <meta name="twitter:url" content="http://www.madconlive.com/" />
        <meta name="twitter:title" content="Madcon - Frontpage" />
        <meta
          name="twitter:description"
          content="Welcome to the official website for Madcon."
        />
        <meta
          name="twitter:image"
          content="http://www.madconlive.com/showcase.jpg"
        />

        <meta property="og:url" content="http://www.madconlive.com/" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Madcon - Frontpage" />
        <meta
          property="og:description"
          content="Welcome to the official website for Madcon."
        />
        <meta
          property="og:image"
          content="http://www.madconlive.com/showcase.jpg"
        />
      </Helmet>
      <section className="fullpage-section">
        <div className="inner-fullpage-container">
          <Navigation />
          <ReactFullpage
            scrollingSpeed={600} /* Options here */
            afterLoad={(origin, destination) => setIndex(destination.index)}
            render={({ state, fullpageApi }) => {
              return (
                <ReactFullpage.Wrapper>
                  {allSongs
                    .sort((a, b) => b.streams - a.streams && !a.latest)
                    .map((song, songIndex) => {
                      return (
                        <Slide
                          allSongs={allSongs}
                          slideIndex={index}
                          song={song}
                          songIndex={songIndex}
                          fullpageApi={fullpageApi}
                        />
                      );
                    })}
                </ReactFullpage.Wrapper>
              );
            }}
          />
        </div>
      </section>
    </>
  );
};

export default withRouter(Frontpage);
