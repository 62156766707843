import React from 'react';
import ReactDOM from 'react-dom';
import Frontpage from './pages/frontpage/'
import Booking from './pages/booking/'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Switch>
        <Route path="/" component={Frontpage} exact={true}/>
        <Route path="/booking" component={Booking} exact={true}/>
      </Switch>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
