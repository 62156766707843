import React from "react";
import Separator from "../../img/page-separator.png";
import {
  fadeIn,
  fadeInDown,
  fadeInUp,
  fadeInRight,
  fadeInLeft,
  slideInRight,
  slideOutLeft,
  fadeOut,
} from "react-animations";
import Radium, { StyleRoot } from "radium";
import "./style.scss";

const styles = {
  fadeInDown: {
    animation: "x 2s",
    animationName: Radium.keyframes(fadeInDown, "fadeInDown"),
  },
  fadeInUp: {
    animation: "2s",
    animationName: Radium.keyframes(fadeInUp, "fadeInUp"),
  },
  fadeInRight: {
    animation: "1.5s",
    animationName: Radium.keyframes(fadeInRight, "fadeInRight"),
  },
  fadeIn: {
    animation: "3s",
    animationName: Radium.keyframes(fadeIn, "fadeIn"),
  },
  fadeInLeft: {
    animation: "1.5s",
    animationName: Radium.keyframes(fadeInLeft, "fadeInLeft"),
  },
  slideInRight: {
    animation: "1.5s",
    animationName: Radium.keyframes(slideInRight, "slideInRight"),
  },
  slideOutLeft: {
    animation: "1.5s",
    animationName: Radium.keyframes(slideOutLeft, "slideOutLeft"),
  },
  fadeOut: {
    animation: "0.4s",
    animationName: Radium.keyframes(fadeOut, "fadeOut"),
  },
};

const Index = ({ allSongs, slideIndex, song, songIndex, fullpageApi }) => {
  console.log(allSongs.length, "hey");
  return (
    <div className="single-slide one section">
      <StyleRoot>
        <div className="inner-slide-container">
          <div className="video-container">
            <iframe
              width="100%"
              style={[
                slideIndex === songIndex ? styles.fadeIn : null,
                { opacity: slideIndex === songIndex ? 1 : 0, minWidth: "100%" },
              ]}
              className="video-player"
              src={song.video ? song.video : null}
              title="YouTube video player"
              loading="lazy"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <div className="details-container">
            <h2
              style={[
                slideIndex === songIndex ? styles.fadeInUp : null,
                { opacity: slideIndex === songIndex ? 1 : 0 },
              ]}
              className="song-title"
            >
              {song.name ? song.name : null}
            </h2>
            <img
              style={[
                slideIndex === songIndex ? styles.fadeInLeft : null,
                { opacity: slideIndex === songIndex ? 1 : 0 },
              ]}
              alt="separator"
              className="separator"
              src={Separator}
            />
            <p
              style={[
                slideIndex === songIndex ? styles.fadeInDown : null,
                { opacity: slideIndex === songIndex ? 1 : 0 },
              ]}
              className="song-details"
            >
              <span className="single-detail">
                <i className="far fa-clock"></i>{" "}
                {song.length ? song.length : null}
              </span>
              <span className="single-detail">
                <i className="far fa-calendar-alt"></i>{" "}
                {song.release ? song.release : null}
              </span>
              <span className="single-detail">
                <i className="fas fa-music"></i>{" "}
                {song.label ? song.label : null}
              </span>
            </p>
            <h4
              style={[
                slideIndex === songIndex ? styles.fadeIn : null,
                { opacity: slideIndex === songIndex ? 1 : 0 },
              ]}
              className="song-stream"
            >
              Stream now on:{" "}
              <a href={song.spotify ? song.spotify : null}>
                <i className="fab fa-spotify spotify-icon"></i>
              </a>{" "}
              <a href={song.itunes ? song.itunes : null}>
                <i className="fab fa-apple apple-music-icon"></i>
              </a>
            </h4>
          </div>
        </div>
        {slideIndex === allSongs.length - 1 ? null : (
          <div className="cursor-hover-container">
            <button
              onClick={() => fullpageApi.moveSectionDown()}
              className="scroll-down-cursor"
            >
              <i className="fas fa-chevron-down"></i>
            </button>
          </div>
        )}
      </StyleRoot>
    </div>
  );
};

export default Index;
